.container {
	position: relative;
	margin-top: 115px;
}

.container img {
	display: block;
	width: 100%;
}

@media screen and (max-width: 1120px) {
	.container {
		margin-top: 100px;
	}
}