.HomePageAboutPart {
    display: flex;
    width: 100%;
}

.img1 {
    display: block;
    width: 50%;
    -webkit-clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
}

.Home-page-About-highlight-text {
    color: var(--pink) !important;
}

.img2 {
    display: block;
    width: 50%;
    -webkit-clip-path: polygon(0 0, 77% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 77% 0, 100% 100%, 0 100%);
}

.HomePageAboutPart div {
    padding: 0.2em 0em 6em 2em;
}

.HomePageAboutPart div h1 {
    text-align: start;
    font-size: 1.55rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    line-height: 1.42em;
    color: var(--heading);

}

.HomePageAboutPart-2-text {
    padding-top: 3em;
}

.HomePageAboutPart div p {
    color: var(--gray-text);
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6em;
}

.HomePageAboutPart div button {
    background-color: white;
    padding: 10px 20px;
    border: 3px solid var(--btn-bg);
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 600;
    line-height: 1.3em;
    letter-spacing: 0.16em;
    color: var(--btn-bg);
    /* margin-top: 30px; */
}

.HomePageAboutPart div button:hover {
    background-color: var(--btn-bg);
    cursor: pointer;
    color: var(--btn-color);
    border: 3px solid var(--btn-bg)
}

.HomePageAboutPart div li {
    text-decoration: none;
    display: flex;
    height: 55px;
    width: 100%;
    align-items: center;
}

.HomePageAboutPart div li:nth-child(6) {
    padding-bottom: 0px;
}

.HomePageAboutPart div li:nth-child(7) {
    margin-bottom: 30px;
}

.HomePageAboutPart div li p {
    text-align: start;
    font-size: 1.3rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    display: inline-block;
    color: black;
}

.line-div {
    width: 32px;
    border-bottom: 5px solid var(--pink);
    margin-right: 30px;
}

@media screen and (max-width: 1024px) {
    .HomePageAboutPart {
        flex-wrap: wrap;

    }

    .HomePageAboutPart img {
        display: block;
        width: 100%;
    }

    .HomePageAboutPart div {
        padding: 1.2em 2em 4em 2em;
    }

}

@media (min-width: 1024px)and (max-width: 1080px) {
    .HomePageAboutPart div li {
        height: 70px;
    }

    .HomePageAboutPart div {
        padding: 4em;
    }

}

@media screen and (max-width: 768px) {
    .HomePageAboutPart-2-text {
        padding-top: 0em;
    }
}