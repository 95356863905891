.AboutPage {
    margin-top: 115px;
}

.AboutPagePart-1,
.AboutPagePart-2 {
    display: flex;
    width: 100%;
}

.AboutPagePart-1 div,
.AboutPagePart-2 div {
    width: 50%;
}

.AboutPageTextPart-1 {
    padding: 1.7em 3em 3em;

}

.AboutPageTextPart-2 {
    padding: 4em 3em 3em 5em;
}

.AboutPageTextPart-2 p {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.6em;
    text-align: start;
    color: var(--gray-text);
    margin-top: -10px;
}

.AboutPageTextPart-2 h3 {
    color: #7A7F92;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.3em;
    margin-top: 40px;
}

.AboutPageImage-1 {
    background-image: url('../../../src/Images/about.jpg');
    background-position: center;
    background-size: cover;
    -webkit-clip-path: polygon(22% 0%, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(33% 0%, 100% 0, 100% 100%, 0 100%);

}

.AboutPageImage-2 {
    background-image: url('../../../src/Images/dp3.jpg');
    background-position: center;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);

}


.AboutPagePart-1 div h1 {
    text-align: start;
    font-size: 1.3rem;
    line-height: 1.33em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: var(--heading);
}

.AboutPagePart-2 div h1 {
    text-align: start;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.2em;
    color: var(--heading);
}

.AboutPagePart-2 .About-page-highlight-text {
    color: var(--pink);
}

.About-page-highlight-text {
    font-family: 'Roboto', sans-serif;
    color: var(--pink);
    font-size: 1.1rem;
    line-height: 1.6em;
    margin-bottom: 20px;
}

.AboutPagePart-1 div p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.6em;
}

.AboutPagePart-1 div li {
    text-decoration: none;
    display: flex;
    width: 600px;
    align-items: center;
}

.AboutPagePart-1 div li p {
    font-size: 1.2rem;
    color: black;
    width: 540px;
    text-align: start;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    line-height: 1.3em;
    letter-spacing: 1.5px;
}

.about-page-line-div {
    width: 60px !important;
    border-bottom: 5px solid var(--pink);
    margin-right: 30px;
}

@media screen and (max-width: 1024px) {

    .AboutPagePart-1,
    .AboutPagePart-2 {
        flex-wrap: wrap;
    }

    .AboutPagePart-1 div,
    .AboutPagePart-2 div {
        width: 100%;
    }

    .AboutPageImage-1,
    .AboutPageImage-2 {
        height: 400px;
    }

    .AboutPageTextPart-2,
    .AboutPageTextPart-1 {
        padding: 2em;
    }

    .AboutPagePart-1 div li {
        width: 100%;
        margin: 0px auto;
    }

    .AboutPagePart-1 div li p {
        width: 540px;

    }

    .about-page-line-div {
        width: 100% !important;

    }

}

@media screen and (max-width: 767px) {
    .AboutPagePart-1 div li {
        display: block;
    }

    .AboutPageTextPart-2 {
        padding: 2em;
    }

    .AboutPageTextPart-1 {
        padding: 0em 2em 2em 2em;
    }

    .AboutPagePart-1 div li p {
        font-size: 18px;
        width: 100%;
    }
}

@media screen and (max-width: 1120px) {
    .AboutPage {
        margin-top: 100px;
    }
}