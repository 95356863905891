.HomePageProjects-part {
    background-color: var(--btn-color);
    padding: 20px;
}

.HomePageProjects {
    width: 90%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
}

.HomePageProjects h2 {
    padding: 70px 0px 40px 0px;
    text-align: center;
    color: var(--heading);
}

.home-page-projects-img-div {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.home-page-projects-img {
    width: 100%;
    height: 14rem;
    object-fit: cover;
    display: block;
}

.project-view-btn {
    background-color: white;
    padding: 10px 20px;
    width: 200px;
    position: relative;
    color: var(--btn-bg);
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    border: 3px solid var(--btn-bg);
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 600;
    line-height: 1.3em;
    letter-spacing: 0.16em;
    margin-bottom: 70px;
}

.project-view-btn:hover {
    background-color: var(--btn-bg);
    cursor: pointer;
    color: var(--btn-color);
    border: 3px solid var(--btn-bg);
}

.project-Name {
    background: var(--heading);
    color: var(--color);
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.owl-nav {
    display: none;
}

.owl-dots {
    margin: 33px 0px 22px 0px;
}

.owl-dot span {
    border-radius: 0px !important;


}

.owl-dot.active span {
    /* background-color: var(--btn-bg) !important; */
}

.box {
    overflow: hidden;
    position: relative;
}

.box img {
    display: block;
    width: 100%;
}

.box .box-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease 0.5s;
}

.box:before,
.box:after,
.box .box-content:before,
.box .box-content:after,
.box .box-overlay {
    content: "";
    width: 20%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: scale(1.2);
    transition: all 0.3s ease 0.1s;
}

.box:after {
    left: 20%;
    transition-delay: 0.2s;
}

.box .box-content:before {
    left: 40%;
    transition-delay: 0.3s;
}

.box .box-content:after {
    left: 60%;
    transition-delay: 0.4s;
}

.box .box-overlay {
    left: 80%;
    transition-delay: 0.5s;
}

.box:hover:before,
.box:hover:after,
.box:hover .box-content:before,
.box:hover .box-content:after,
.box:hover .box-overlay {
    opacity: 1;
    transform: scale(1);
}

.box .inner-content {
    width: 100%;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    z-index: 1;
    transform: translate(-50%, -50%) scale(1.5);
    transition: all 0.3s ease 0.5s;
}

.box:hover .inner-content {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.box .title {
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

.box .post {
    display: inline-block;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin: 5px 0 20px 0;
}

.box .icon {
    padding: 0;
    margin: 0;
    list-style: none;
}

.box .icon li {
    display: inline-block;
    margin: 0 5px;
}

.box .icon li a {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50% 0;
    background: #8421ef;
    font-size: 18px;
    color: #fff;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5), 0 0 0 4px rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease 0s;
}

.box .icon li a:hover {
    background: #fff;
    color: #8421ef;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5) inset, 0 0 10px #fff;
}

@media only screen and (max-width:990px) {
    .box {
        margin-bottom: 30px;
    }

}

@media screen and (max-width:920px) {
    .owl-dots {
        display: none;
    }

    .HomePageProjects {
        width: 95%;
    }
}