.error-container {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: sans-serif;
  background-color: var(--app-bg);
  height: 100vh;
  width: 100%;
}

.error-container h1,
a {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.section {
  padding: 4rem 2rem;
}

.section .error {
  font-size: 150px;
  color: rgb(7, 7, 192);
  text-shadow:
    1px 1px 1px rgb(1, 1, 120),
    2px 2px 1px rgb(1, 1, 120),
    3px 3px 1px rgb(1, 1, 120),
    4px 4px 1px rgb(1, 1, 120),
    5px 5px 1px rgb(1, 1, 120),
    6px 6px 1px rgb(1, 1, 120),
    7px 7px 1px rgb(1, 1, 120),
    8px 8px 1px rgb(1, 1, 120),
    25px 25px 8px rgba(0, 0, 0, 0.2);
}

.page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.back-home {
  display: inline-block;
  border: 2px solid var(--btn-bg);
  color: var(--btn-bg);
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.back-home:hover {
  background: var(--btn-bg);
  color: #ddd;
}

.error-copyright-text {
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .section {
    padding: 4rem 2rem;
  }

  .section .error {
    font-size: 90px;
  }

  .page {
    margin: 2rem 0;
    font-size: 18px;
  }
}