@import url('https://fonts.googleapis.com/css2?family=Signika:wght@600&display=swap');

nav {
	position: fixed;
	z-index: 10;
	left: 0;
	right: 0;
	top: 0;
	font-family: 'Montserrat', sans-serif;
	padding: 0 5%;
	height: 115px;
	background-color: var(--navbar);
}

nav .logo {
	float: left;
	width: 40%;
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 38px;
	color: var(--nav-color);


}

nav .logo strong {
	font-family: 'Signika', sans-serif;
}

nav .logo p {
	font-size: 18px;
	color: var(--app-bg);
	margin-left: 4px;
	margin-top: 33px;

}

nav .logo img {
	width: 93px;
	margin-right: 4px;
	margin-left: 0px;
}

nav .links {
	float: right;
	padding: 0;
	margin: 0;
	width: 60%;
	height: 100%;
	display: flex;
	align-items: center;
}

nav .links li {
	list-style: none;
}

nav .links a {
	display: block;
	padding: 1em;
	color: #fff;
	text-decoration: none;
	font-size: 1rem;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: .7px;
	position: relative;
	transition: all ease-in-out 250ms;

}

.navbar-list a::after {
	content: "";
	position: absolute;
	left: 0%;
	display: block;
	height: 4px;
	width: 0%;
	background-color: var(--nav-color);
	bottom: -2em;
}

.navbar-list a.active::after {
	content: "";
	position: absolute;
	left: 0%;
	display: block;
	height: 4px;
	width: 100%;
	background-color: var(--nav-color);
	bottom: -2em;
}

.navbar-list a.active {
	color: var(--nav-color);
	font-weight: 500;
}

.navbar-list a:hover {
	color: var(--nav-color);
	padding: 12px 45px;
	background: #e7eee52c;
	transition: all .3s ease;
}


#nav-toggle {
	position: absolute;
	top: -100px;
}

nav .icon-burger {
	display: none;
	position: absolute;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
}

nav .icon-burger .line {
	width: 30px;
	height: 5px;
	background-color: #fff;
	margin: 5px;
	border-radius: 3px;
	transition: all .3s ease-in-out;
}


.social-media {
	display: none;
}

@media screen and (max-width: 1120px) {
	nav {
		padding: 0% 1.7%;
		height: 100px;
	}

	nav .logo {
		float: none;
		width: auto;
		justify-content: flex-start;
	}

	nav .links {
		float: none;
		position: fixed;
		z-index: 9;
		left: 0;
		right: 0;
		top: 100px;
		bottom: 100%;
		width: auto;
		height: auto;
		display: inline-block;
		overflow-y: scroll;
		background-color: var(--togglebar);
		box-sizing: border-box;
		transition: all .5s ease-in-out;
	}

	nav .links a {
		font-size: 26px;
		padding: 0.68em;
	}

	nav :checked~.links {
		bottom: 0;
	}

	nav .icon-burger {
		display: block;
	}

	nav :checked~.icon-burger .line:nth-child(1) {
		transform: translateY(10px) rotate(225deg);
	}

	nav :checked~.icon-burger .line:nth-child(3) {
		transform: translateY(-10px) rotate(-225deg);
	}

	nav :checked~.icon-burger .line:nth-child(2) {
		opacity: 0;
	}

	.navbar-list a:hover::after {
		width: 0%;
	}




	.navbar-list a:hover {
		background: #e7eee52c;
	}

	.navbar-list:first-child {
		padding-top: 40px;
	}

	.navbar-list:nth-child(5) {
		padding-bottom: 55px;
	}

	.social-media {
		display: flex;
		align-items: center;
		padding-bottom: 20px;
		margin-left: 10px;

	}

	.social-media a {
		font-size: 22px !important;
		margin: 0px 0px 0px 10px;
		background-color: var(--pink);
		padding: 0.3em 0.4em 0.1em !important;
	}

	.navbar-list a.active::after {
		display: none;
	}

}

@media (min-width: 768px) and (max-width: 1120px) {
	nav .logo img {
		width: 70px;
		margin-right: 5px;
		margin-left: 8px;
	}
}

@media screen and (max-width: 768px) {
	nav .logo img {
		width: 60px;
		margin-right: 4px;
		margin-left: 5px;
	}

	nav .logo p {
		font-size: 16px;
		margin-left: 4px;
		margin-top: 28px;

	}

	nav .logo {
		width: 90%;
		font-size: 33px;
	}
}