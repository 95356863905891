.project-detail-container {
    padding: 130px 0px;
}

.project-title {
    display: flex;
    align-items: center;
    margin-top: -20px;
}

.project-title h2 {
    display: flex;
    align-items: start;
    background: rgba(0, 0, 0, 0.5);
    padding: 15px 35px;
    color: var(--color);
}
.delivery-texts-part-1 li{
}

#back-to-projects-btn,
#next,
#prev {
    background-color: white;
    padding: 4px 6px;
    color: var(--btn-bg);
    border: none;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
}
#back-to-projects-btn {
    padding: 5.8px 6px;
}

.btn-div{
    margin-top: 10px;
    display: flex;
    align-items: center;
}
#next:hover,
#prev:hover,
#back-to-projects-btn:hover {
    background-color: rgb(0, 59, 142);
    cursor: pointer;
    color: var(--btn-color);
}

#next:disabled,
#prev:disabled {
    display: none;
}

.md-icon {
    font-size: 20px;

}

.play-btn {
    position: absolute;
    top: 40%;
    left: 45%;
    transform: translate(-40%, -45%);
    font-size: 30px;
    z-index: 100;
    text-align: center;
    cursor: pointer;
}

.project-title h3 {
    display: flex;
    height: fit-content;
    padding: 6px 7px;
    margin-left: -23px;
    background-color: black;
    color: var(--color);
}

.Scope-title h4 {
    background: var(--togglebar);
    color: var(--color);
    text-align: start;
    padding: 4px 6px;
}

.Scope-title {
    margin-top: -41px;
}

.Scope-title li {
    margin-top: -5px;
}

.project-container-carousel {
    padding: 0px 40px !important;
    background: #f0f0f5;
}

.delivery-div {
    background-color: var(--togglebar);
    color: var(--color);
    padding: 0px 20px 15px 20px;
}

.delivery-div li {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6em;
}

.Scope-title li {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6em;
}

.delivery-texts {
    display: flex;
    align-items: center;
}

.delivery-texts-part-1 {
    margin-right: 60px;
}
.delivery-div h2 {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.Projects-Detailing-text {
    width: 90%;
    margin: 0px auto 15px;
    display: flex;
    justify-content: center;
    text-align: start;
    align-items: start;

}


.item {
    width: 100%;
    position: relative;
    background-color: var(--color);

}

.item img {
    width: 100%;
    object-fit: cover;
    display: block;


}

.item video {
    width: 100%;
    display: block;
}

.slick-next:before,
.slick-prev:before {
    color: var(--togglebar) !important;
}



@media screen and (max-width: 1024px) {
    .delivery-div li {
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.6em;

    }

    .delivery-texts {
        flex-wrap: wrap;
    }

    .delivery-texts-part-1 {
        margin-right: 100px;
    }

    .Projects-Detailing-text {
        display: block;
        width: 90%;
        margin: 0px auto;
    }

    .delivery-div {
        margin: 20px auto;
        padding: 10px 20px 28px 20px;

    }

    .project-title h3 {
        margin-left: 5px;
        padding: 0px 0px;
        background: none;
        color: var(--togglebar)
    }

    .project-title {
        margin-top: -15px;
        align-items: center;
        justify-content: center;
        background: white;
        border: 5px dotted var(--nav-color);
        display: flex;
        margin-right: 0%;
    }

    .project-title h2 {
        background: none;
        padding: 0px;
        color: var(--gray-text);
    }

    .Scope-title h4 {
        background: none;
        margin-bottom: 0px;
        font-size: 18px;
        color: black;
    }

    .Scope-title {
        margin-top: -5px;
    }

    .Scope-title li {
        line-height: 1.5em;
        margin-top: 3px;
    }

}

@media screen and (min-width: 1024px) {
    .project-title {
        margin-right: 250px;
    }
}

@media screen and (max-width: 767px) {

    .project-title h2,
    .project-title h3 {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    .project-title h2,
    .project-title h3 {
        font-size: 22px;
    }
}



.trailer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .9);
    z-index: 10000;
    visibility: hidden;
    opacity: 0;
}

.video-container {
    display: none;
}

.active {
    visibility: visible;
    opacity: 1;
}

.trailer video {
    max-width: 900px;
    outline: none;
    position: relative;


}

.close {
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 25px;
    font-weight: bold;
    filter: invert(1);
    cursor: pointer;

}

@media (max-width: 901px) {
    .trailer video {
        max-width: 90%;
    }
}

.bg,
.button {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.bg {
    animation: pulse 1.2s ease infinite;
    background: var(--pink);
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 99;
    border: none;
    background: var(--pink);
    background-size: 18px;
    cursor: pointer;
    outline: none;
}

.button BsPlayFill {
    position: absolute;
    color: white !important;
    font-size: 17px;
}

@keyframes pulse {
    0% {
        transform: scale(1, 1);
    }

    50% {
        opacity: 0.3;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}