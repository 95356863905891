:root {
  --pink: #0094d3;
  /* --togglebar:#132340; */
  --togglebar:#1a325a;
  --navbar: #031f4b ;
  /* --navbar: #231f20 ; */
  --btn-bg: #505a74;
  --btn-color: white; 
  --color:white;
  --heading: #0f256e ;
  --nav-color:#99d0ed;
  --app-bg:#eaebfe ;
  --footer-bg:#bfd7f1;
  /* --footer-bg:#172b40e7;
  --footer-bg:#172b40e7; */
  --gray-text:#7a7a7a;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto+Flex:wght@500&family=Roboto+Slab:wght@500&display=swap');
@import "react-alice-carousel/lib/alice-carousel.css";
html,body {
  margin:0;
  padding:0;
  overflow-x:hidden;
  width: 100%;
}

.App{
  background-color: var(--app-bg);
}
.scroll-to-top svg{
  fill:var(--pink);

}
a{
  text-decoration: none;
}
