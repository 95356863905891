.home-page-service {
    padding: 5em 2em 8em 2em;
    background-color: #c7cbd9;
}

.Home-page-service-h1 {
    color: var(--heading);
    text-align: center;
    line-height: 1.2em;
    margin-bottom: 35px;
    font-size: 40px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: .4px;
}

.home-page-service-container-1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
}

.fplogo {
    width: 100%;
    z-index: 1;
    height: 100px;
    color: var(--app-bg);
    background: #202020;
    display: flex;
    align-items: center;
    justify-content: start;
}

.fplogo h2 {
    font-family: 'Open Sans', sans-serif;
}

.fplogo img {
    width: 80px;
    filter: grayscale(100%);
}

.home-page-service-img1 {
    background-color: #aeaead;
    width: 100%;
    height: 14rem;
    object-fit: cover;
    display: block;
}


.home-page-service-img-container-1 {
    height: 14rem;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
}


@media (min-width: 768px)and (max-width: 1024px) {
    .home-page-service-container-1 {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

    }

}

@media screen and (max-width: 767px) {
    .home-page-service-container-1 {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;

    }

    .Home-page-service-h1 {
        font-size: 27px;
    }


    .fplogo img {
        margin-right: 10px;
    }
}