.projectscontainerPage {
  padding: 8.4em 3em 5em 3em;
}

.projects-tag {
  color: var(--heading);
  text-align: center;
  line-height: 1.2em;
  text-transform: uppercase;
  font-size: 40px;

  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: .4px;
}

.projects-type {
  text-align: start;
  font-size: 1.5rem;
  line-height: 1.33em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.projects-other-tag {
  padding-top: 60px;
  padding-bottom: 25px;
}

.exp-project-text {
  font-size: 1.15rem;
  line-height: 1.33em;
  padding: 0px 0px 0px 10px;
  font-weight: 600;
}

.projectscontainer-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;

}

.projectscontainer-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.projects-img1 {
  width: 100%;
  height: 14rem;
  object-fit: cover;
  display: block;
}


.projects-img-container-1 {
  height: 14rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: var(--color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay_1 {
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0.1rem;
}

.projects-img-container-2 {
  height: 20rem;
  width: 100%;
  overflow: hidden;
}


@media (min-width: 768px)and (max-width: 1024px) {
  .projectscontainer-1 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

  }

  .projectscontainer-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

  }

  .projects-tag {
    font-size: 33px;
  }

  .projects-type {
    font-size: 1.4rem;
    line-height: 1.3em;
  }
}

@media screen and (max-width: 767px) {
  .projectscontainer-1 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;

  }

  .projectscontainer-2 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;

  }

  .projects-tag {
    font-size: 27px;
  }

  .projects-other-tag {
    padding: 30px 0px 15px 0px;
    line-height: 1.5em;

  }

  .projects-type {
    font-size: 1.1rem;
    line-height: 1.2em;
  }

}