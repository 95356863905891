.Contact {
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  background-color: var(--footer-bg);
}

.Contact-part1 {
  width: 55%;
  padding: 5em 0em 5em 5em;
}

.contact-success {
  color: var(--color);
  padding: 7px 5px;
  font-size: 1.1rem;
  line-height: 1.3em;
  background-color: var(--pink);
  text-align: center;
}

.contact-failure {
  color: red;
  padding: 5px;
  font-size: 1.1rem;
  line-height: 1.3em;
  background-color: var(--color);
  text-align: center;
}

.Contact-part1 li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
}

.Contact-part1 li h4 {
  line-height: 1.5em;
  font-size: 18px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.Contact-part1 li p {
  margin-left: 12px;
}

.Contact-part1 li a {
  text-decoration: none;
  color: black;
}

.Contact-part2 {
  width: 45%;
  padding: 4.5em;
}

.Contact-part2 label,
button {
  margin-bottom: 10px;
  display: list-item;
  list-style: none;
}

.Contact-part2 input,
textarea {
  width: 100%;
  padding: 7px;
  resize: none;
  margin-bottom: 12px;
}

.Contact-part2 button {
  background-color: var(--color);
  padding: 10px 20px;
  border: 3px solid var(--btn-bg);
  text-transform: uppercase;
  color: var(--btn-bg);
  font-size: 0.7em;
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: 0.16em;
}

.Contact-part2 button:hover {
  cursor: pointer;
  background-color: var(--btn-bg);
  color: var(--btn-color);
  border: 3px solid var(--btn-bg);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contact-social-media {
  margin-top: 200px;
}

.contact-social-media a {
  padding: 10px;
  background-color: var(--btn-bg);
  color: var(--btn-color);
  margin: 0px 0px 0px 15px;

}

.copyright-text {
  padding-bottom: 20px;
  text-align: center;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .Contact {
    flex-wrap: wrap;
  }

  .Contact-part1 {
    width: 100%;
    padding: 2em;
  }

  .Contact-part2 {
    width: 100%;
    padding: 2em;
  }

  .contact-social-media {
    margin-top: 50px;
  }

  .Contact-part2 input,
  textarea {
    width: 95%;
  }

}

@media screen and (max-width: 1024px) {

  .contact-success,
  .contact-failure {
    font-size: 1rem;
  }

}