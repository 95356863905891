 .services-tag {
     color: var(--heading);
     text-align: center;
     padding-top: 70px;
     padding-bottom: 10px;
     line-height: 1.2em;
     font-size: 40px;
     font-family: 'Open Sans', sans-serif;
     letter-spacing: .4px;
 }


 .row {
     padding: 3em 3em 0em 3em;

 }

 .featuredPropBox ul {
     font-family: 'Open Sans', sans-serif;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     list-style: outside none none;
     padding: 0;
 }

 .featuredPropBox ul li {
     width: 350px;
     margin: 5px;
 }

 .service-detail-pic {
     background-color: #aeaead;
     background-size: cover;
     background-position: 50% 50%;
     background-repeat: no-repeat;
     display: block;
     height: 350px;
     width: 350px;
 }


 .service-detail-pic-1 {
     background-image: url('../../Images/services-img/Steeldetailing.png');
 }

 .service-detail-pic-2 {
     background-image: url('../../Images/services-img/Precastdetailing.png');
 }

 .service-detail-pic-3 {
     background-image: url('../../Images/services-img/Detailingautomation.png');
 }

 .service-detail-pic-4 {
     background-image: url('../../Images/services-img/estimatemodelling.jpg');
 }

 .fplogo-service {
     width: 100%;
     z-index: 1;
     height: 80px;
     color: var(--app-bg);
     background: #202020;
     display: flex;
     align-items: center;
     justify-content: start;
 }

 .fplogo-service h2 {
     font-family: 'Open Sans', sans-serif;
 }

 .fplogo-service img {
     width: 80px;
     margin-right: 20px;
     filter: grayscale(100%);
 }

 .fptext {
     background-color: var(--heading);
     font-size: 16px;
     text-align: start;
     height: auto;
     width: 350px;
     height: 570px;
     margin: 5px 0px;
 }

 .fptext div {
     width: 90%;
     margin: 10px auto;
     padding: 25px 10px;
 }

 .fptext div p {
     color: #fff;
     margin: 0px auto 9px;
 }

 .featuredPropBox ul li .fptext div button {
     font-size: 16px;
     padding: 10px 0px;
     margin-top: 5px;
     font-weight: bold;
     border: none;
     text-align: center;
     width: 50%;
     background: var(--pink);
     color: var(--btn-color);
     cursor: pointer;
 }



 @media screen and (max-width:767px) {

     .featuredPropBox ul li,
     .service-detail-pic,
     .fptext {
         width: 100%;
     }
 }

 @media (min-width:960px) and (max-width:1192px) {

     .featuredPropBox ul li,
     .service-detail-pic,
     .fptext {
         width: 450px;
     }
 }




 .ServicesPageAboutPart {
     display: flex;
     width: 100%;
     margin-top: 100px;
 }

 .ServicesPageAboutPart div {
     width: 50%;
 }

 .Services-img {
     background-image: url('../../../src/Images/service.jpg');
     background-position: center;
     background-size: cover;
     -webkit-clip-path: polygon(0 0, 77% 0, 100% 100%, 0 100%);
     clip-path: polygon(0 0, 77% 0, 100% 100%, 0 100%);
 }

 .ServicesPageAboutParttext {
     padding: 7em;
 }

 .ServicesPageAboutPart div h1 {
     text-align: start;
     font-size: 1.8rem;
     font-family: 'Open Sans', sans-serif;
     /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
     line-height: 1.2em;
     letter-spacing: .4px;
     color: var(--heading);
 }

 .ServicesPageAboutPart div p {
     color: var(--gray-text);
     font-size: 1rem;
     font-weight: 400;
     font-family: 'Roboto', sans-serif;
     line-height: 1.6em;
     letter-spacing: .2px;
 }

 .ServicesPageAboutPart div button {
     background-color: white;
     padding: 10px 20px;
     border: 3px solid var(--btn-bg);
     text-transform: uppercase;
     font-size: 0.7em;
     font-weight: 600;
     line-height: 1.3em;
     letter-spacing: 0.16em;
     color: var(--btn-bg);
     margin-top: 30px;
     margin-left: 9px;
 }

 .ServicesPageAboutPart div button:hover {
     background-color: var(--btn-bg);
     cursor: pointer;
     color: var(--btn-color);
     border: 3px solid var(--btn-bg)
 }

 .ServicesPageAboutPart div li {
     text-decoration: none;
     margin: 0px;
     display: flex;
     align-items: center;
     justify-content: start;
 }

 .ServicesPageAboutPart div li p {
     text-align: start;
     margin: 10px;
     font-size: 1.3rem;
     font-weight: 400;
     font-family: 'Roboto', sans-serif;
     display: inline-block;
     color: black;
 }

 .line-div-service {
     border-bottom: 5px solid var(--pink);
     width: 30px;

 }

 @media screen and (max-width: 1024px) {
     .ServicesPageAboutPart {
         flex-wrap: wrap;
         margin-top: 50px;

     }

     .ServicesPageAboutPart div {
         width: 100%;
     }

     .Services-img {
         width: 100%;
         height: 400px;
     }

     .row {
         padding: 4em 0em 0em 0em;

     }



 }

 @media screen and (max-width: 768px) {
     .ServicesPageAboutPart div li p {
         font-size: 1.1rem;
         margin: 8px 7px;
     }

     .ServicesPageAboutParttext {
         padding: 3em 1em;
     }

     .services-tag {
         padding-top: 40px;
         font-size: 27px;
     }

     .ServicesPageAboutPart div h1 {
         font-size: 1.52rem;
         line-height: 1.2em;
     }

     .line-div-service {
         border-bottom: 4px solid var(--pink);
         width: 24px;

     }

     .row {
         padding: 4em 0.8em 0em 0.8em;

     }



 }